import { ManagedViewContext } from '@rapid/sdk';
import React from 'react';
import { useCurrentCenterUser } from '../../contexts/current-center-user';
import RecurringVisitors from './recurring-visitors';

export default function TourBookingHOC() {
  const user = useCurrentCenterUser();
  const filter = `centre_id eq '${user.centre_id}' and status eq 'active'`;

  return (
    <ManagedViewContext list="Recurring Visitors" dataSource="All" filter={filter} disableAutoFetch={true}>
      <RecurringVisitors />
    </ManagedViewContext>
  );
}
