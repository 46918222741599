import { AnyListItem } from '@rapid/data-model';
import { ManagedListItemContext, useRapidApplication } from '@rapid/sdk';
import React, { createContext, PropsWithChildren, useContext, useState } from 'react';
import Spinner from '../components/spinner/spinner';
import NoCentreUser from '../routes/no-centre-user/no-centre-user';

export interface ICenterUser extends AnyListItem {
  user: null | string;
  user_id: null | number;

  centre: null | string;
  centre_id: null | number;

  status: null | 'Active' | 'Lapsed';
}

const default_value = {
  user: null,
  user_id: null,

  centre: null,
  centre_id: null,

  status: null,

  __metadata: {
    type: '',
  },
};

export const CurrentCenterUserContext = createContext<ICenterUser>(default_value);

export function useCurrentCenterUser() {
  return useContext(CurrentCenterUserContext);
}

export default function CurrentCenterUserProvider({ children }: PropsWithChildren<unknown>) {
  const [centerUser, setCenterUser] = useState<ICenterUser>(default_value);
  const [isLoading, setLoading] = useState(true);
  const [{ currentPrincipalId }] = useRapidApplication();

  async function onPostProcess(item: AnyListItem) {
    setCenterUser(item as ICenterUser);
    setLoading(false);
    return item;
  }

  if (!isLoading && !centerUser?.centre_id) {
    return <NoCentreUser />;
  }

  return (
    <CurrentCenterUserContext.Provider value={centerUser}>
      <ManagedListItemContext
        postProcess={onPostProcess}
        $filter={`user_id eq '${currentPrincipalId}'`}
        list="Centre Users"
      >
        {isLoading && <Spinner />}
        {!isLoading && <>{children}</>}
      </ManagedListItemContext>
    </CurrentCenterUserContext.Provider>
  );
}
