import { WindowFetch } from '@rapid/sdk';
import { ISiteAPIEndpoint } from '@rapid/sdk/lib/api/rapid-platform.api';
import { IApplicationContext } from '@rapid/sdk/lib/rapid-application';
import { DateTime } from 'luxon';
import { IData } from '../../../contexts/contextual-data';
import { ICenterUser } from '../../../contexts/current-center-user';
import { ContactBody } from '../../../types';
import { IProcessedFormData } from '../utils';
import { createVisitor, getContactOrCreate, updateTour } from './general';

export async function BookedTours(
  data: IProcessedFormData[],
  ep: ISiteAPIEndpoint,
  app: IApplicationContext,
  graph: WindowFetch,
  centreUser: ICenterUser,
  contextualData: Partial<IData>,
) {
  for (let i = 0; i < data.length; i++) {
    const rawContact = data[i]['Contacts'] as ContactBody;
    const rawVisitor = data[i]['Visitors'];

    const { contact, family_id } = await getContactOrCreate(ep, rawContact);

    if (i === 0) {
      await updateTour(ep, { id: contextualData.data!.id, tour_status: 'Attended' } as any);
    }

    const visitor = await createVisitor(ep, {
      LinkedItemsToAdd: [`tours/${contextualData.data!.id}`],
      primary_contact_id: contact.id as number,
      visit_reason: 'Pre-Booked Tour',
      family_id: family_id,
      status: 'SignedIn',
      centre_id: centreUser.centre_id!,
      sign_in_time: DateTime.now().toUTC().toISO(),
      ...rawVisitor,
    });
  }
}
