import React from 'react';
import './error-box.scss';

interface IProps {
  error?: string | string[];
  groupErrors?: Record<string, any[]>;
}

export default function ErrorBox({ error, groupErrors }: IProps) {
  if ((typeof error === 'string' || error?.length !== 0) && error) {
    return (
      <div className="form-error-box">
        <h5 className="form-error-box__header">The following fields are required:</h5>
        {typeof error !== 'undefined' && (
          <p className="form-error-box__text">
            {typeof error === 'string' ? <span>{error}</span> : error.map((e) => <span key={e}>{e}</span>)}
          </p>
        )}
      </div>
    );
  }

  const groupErrorKeys = Object.keys(groupErrors ?? {});

  if (groupErrorKeys.length > 0) {
    return (
      <React.Fragment>
        <div className="form-error-box" style={{ marginTop: 0 }}>
          <h5 className="form-error-box__header">Please complete either one of the groups below</h5>

          {groupErrorKeys.map((key) => (
            <React.Fragment key={key}>
              <h6 className="form-error-box__sub-header">Group: {key}: </h6>

              <p className="form-error-box__text">
                {groupErrors![key].map((e) => (
                  <span key={e}>{e}</span>
                ))}
              </p>
              <br></br>
            </React.Fragment>
          ))}
        </div>
      </React.Fragment>
    );
  }

  return <br />;
}
