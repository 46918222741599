import { IElement } from '@rapid/adaptive-framework';
import {
  useCurrentSiteEndpoint,
  useGraphAuthenticatedFetch,
  useListItemContext,
  useRapidApplication,
} from '@rapid/sdk';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import AdaptiveForm from '../../components/adaptive-form/adaptive-form';
import ErrorBox from '../../components/error-box/error-box';
import PageLayout from '../../components/page-layout/page-layout';
import Spinner from '../../components/spinner/spinner';
import WaitingOverlay from '../../components/waiting-overlay/waiting-overlay';
import { useContextualData } from '../../contexts/contextual-data';
import { useCurrentCenterUser } from '../../contexts/current-center-user';
import './form-viewer.scss';
import { AllOtherVisitors } from './submission/all-other-visitors';
import { BookedTours } from './submission/booked-tours';
import { Orientations } from './submission/orientations';
import { RecurringVisitors } from './submission/recurring-visitor';
import { WalkInTours } from './submission/walk-in-tours';
import { adaptiveFormChangeHandler, validateFormData } from './utils';

type ConnectionID = string;

export interface IFormData {
  connections: ConnectionID[];
  value: Record<ConnectionID, any>;
  globalChoices: Record<string, any>;
}

export interface IProcessedFormData {
  Contacts: Record<string, any>;
  Visitors: Record<string, any>;
  [key: string]: any;
}

function FormViewer() {
  const [contextualData, setContextualData] = useContextualData();
  const [isSubmitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [groupErrors, setGroupErrors] = useState<any>({});
  const [list] = useListItemContext() as any;
  const location = useLocation();
  const history = useHistory();
  const formData = useRef<IFormData>({ connections: [], globalChoices: {}, value: {} });

  const ep = useCurrentSiteEndpoint();
  const [app] = useRapidApplication();
  const graphClient = useGraphAuthenticatedFetch('Files.ReadWrite.All', 'Sites.ReadWrite.All');
  const centreUser = useCurrentCenterUser();

  const onAdaptiveFormSave = async () => {
    const {
      errors: _errors,
      groupErrors: _groupErrors,
      data,
    } = validateFormData(formData.current, list.item?.document?.body);

    let hasErrors = false;
    let hasGroupErrors = false;

    if (_errors && _errors.length) {
      hasErrors = true;
      setErrors(_errors);
    } else {
      setErrors([]);
    }

    for (const key of Object.keys(_groupErrors)) {
      const hasMissingValues = _groupErrors[key].some((value) => !value[0]);
      if (!hasMissingValues) {
        hasGroupErrors = false;
        setGroupErrors({});
        break;
      }

      hasGroupErrors = true;
    }

    if (hasGroupErrors) {
      const newGroupErrors: any = {};

      for (const key of Object.keys(_groupErrors)) {
        newGroupErrors[key] = _groupErrors[key].reduce((result, [value, label]) => {
          if (!value) result.push(label);
          return result;
        }, [] as string[]);
      }

      setGroupErrors(newGroupErrors);
    }

    if (!data || hasErrors || hasGroupErrors) return;

    setErrors([]);
    setSubmitting(true);

    try {
      if (location.pathname.includes('/form/all-other-visitors'))
        await AllOtherVisitors(data, ep, app, graphClient, centreUser, contextualData);

      if (location.pathname.includes('/form/recurring-visitor'))
        await RecurringVisitors(data, ep, app, graphClient, centreUser, contextualData);

      if (location.pathname.includes('/form/booked-tour'))
        await BookedTours(data, ep, app, graphClient, centreUser, contextualData);

      if (location.pathname.includes('/form/orientation'))
        await Orientations(data, ep, app, graphClient, centreUser, contextualData);

      if (location.pathname.includes('/form/walk-in-tour'))
        await WalkInTours(data, ep, app, graphClient, centreUser, contextualData);
    } catch (e) {
      setSubmitting(false);
    }

    history.push('/sign-out');
  };

  const onAdaptiveFormChange = (element: IElement, data: any, path: any = '') => {
    adaptiveFormChangeHandler(element, data, path, formData.current, list.item!.document?.body);
  };

  useEffect(function cleanUp() {
    return function () {
      setContextualData({});
    };
  }, []);

  if (list.loading) {
    return (
      <main className="tour-booking-route">
        <Spinner />
      </main>
    );
  }

  return (
    <PageLayout title={contextualData.form_title ?? 'walk-in-tour'} backHref={contextualData.back_href}>
      {isSubmitting && <WaitingOverlay text="Please Wait" />}

      <AdaptiveForm
        onAdaptiveFormChange={onAdaptiveFormChange}
        rendererId={contextualData.form_title ?? 'walk-in-tour'}
        schema={list.item?.document?.body}
        preFill={contextualData.adaptive_prefill ?? {}}
        onData={(data) => (formData.current.value = data)}
      />

      <ErrorBox error={errors} />
      <ErrorBox groupErrors={groupErrors} />

      <button onClick={onAdaptiveFormSave} className="btn btn-primary">
        Save
      </button>
    </PageLayout>
  );
}

export default FormViewer;
