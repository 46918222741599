import { useCurrentSite, useViewContextListItemArray } from '@rapid/sdk';
import React, { useMemo } from 'react';
import PageLayout from '../../components/page-layout/page-layout';
import { IChoice } from '@rapid/data-model';
import { VisitorFormListItem } from '../../types';
import FormCard from '../../components/form-card/form-card';

export default function AllVisitors() {
  const [site] = useCurrentSite();
  const [visitorForms] = useViewContextListItemArray() as [any[], any];
  const colors = useMemo(function () {
    const list = site?.Lists.find((l) => l.ListName === 'Visitor Types');
    return (list?.Fields.find((f) => f.ColumnName === 'colour')?.Settings?.Choices ?? []) as IChoice[];
  }, []);

  return (
    <PageLayout title="All Visitors">
      <div className="form-card-container">
        {visitorForms.map(({ title, id, colour }: VisitorFormListItem) => {
          const hex = colors.find((c) => c.Text === colour)?.AccentColour;
          return <FormCard key={id} title={title} href={`/form/all-other-visitors/${id}`} baseColor={hex ?? 'black'} />;
        })}
      </div>
    </PageLayout>
  );
}
