import { UploadSession, WindowFetch } from '@rapid/sdk';
import { ISiteAPIEndpoint } from '@rapid/sdk/lib/api/rapid-platform.api';
import { DateTime } from 'luxon';
import { IData } from '../../../contexts/contextual-data';
import { ICenterUser } from '../../../contexts/current-center-user';
import { ContactBody, FamilyBody, LeadBody, OrientationListItem, TourBody, VisitorListItem } from '../../../types';

export async function getContactOrCreate(
  _ep: ISiteAPIEndpoint,
  _contact: ContactBody,
  _createFamily = true,
): Promise<{ contact: ContactBody; family_id: number; created: boolean }> {
  let contact = await findContact(_ep, _contact);

  if (contact) contact = await updateContact(_ep, { ..._contact, id: contact.id });

  if (contact && !contact.family_id && _createFamily) {
    const family = await createFamily(_ep, _contact);
    contact = await updateContact(_ep, { ...contact, id: contact.id, family_id: +family.id! });
  }

  if (contact) return { contact, family_id: contact.family_id, created: false };

  if (_createFamily) {
    const family = await createFamily(_ep, _contact);
    contact = await createContact(_ep, { ..._contact, family_id: family.id as number });

    return { contact, family_id: contact.family_id, created: true };
  } else {
    contact = await createContact(_ep, { ..._contact });

    return { contact, family_id: contact.family_id, created: true };
  }
}

export async function getRecurringVisitorOrCreate(
  _ep: ISiteAPIEndpoint,
  _contact: ContactBody,
  _centreUser: ICenterUser,
  _contextualData?: Partial<IData>,
) {
  const visit_type =
    _contextualData?.data?.visitor_type_id ||
    _contextualData?.data?.visitor_reason_id ||
    _contextualData?.data?.visitor_status_id;

  const recurring = await _ep.Lists['Recurring Visitors'].All.Items.getJson({
    $filter: `primary_contact_id eq '${_contact.id}' and centre_id eq '${_centreUser.centre_id}'`,
  });

  if (!!recurring?.value?.length) {
    return await _ep.Lists['Recurring Visitors'].All.Items[recurring.value[0].id].putJson(undefined, {
      last_verification_date: DateTime.now().toISO(),
      status: 'Active',
    });
  }

  const newRecurring = await _ep.Lists['Recurring Visitors'].All.Items.postJson(undefined, {
    centre_id: _centreUser.centre_id,
    primary_contact_id: _contact.id,
    last_verification_date: DateTime.now().toISO(),
    visitor_type_id: visit_type,
    status: 'Active',
  });

  return newRecurring[0];
}

export function processFiles(_visitor: any): any[] {
  let embedded_files: any[] = [];

  if (_visitor.embedded_files?.length) {
    embedded_files = [..._visitor.embedded_files];
  } else {
    for (const key of Object.keys(_visitor)) {
      if (_visitor[key] instanceof FileList) {
        embedded_files = [..._visitor[key]];
      }
    }
  }

  delete _visitor['embedded_files'];
  delete _visitor['~'];
  return embedded_files;
}

async function findContact(_ep: ISiteAPIEndpoint, _contact: ContactBody): Promise<ContactBody> {
  if (_contact.id) return await _ep.Lists['Contacts']['All$'].Items[_contact.id].getJson();
  let res = await _ep.Lists['Contacts']['All$'].Items.getJson({ $filter: `email eq '${_contact.email}'` });
  if (!res?.value?.length) {
    res = await _ep.Lists['Contacts']['All$'].Items.getJson({ $filter: `mobile eq '${_contact.mobile}'` });
  }
  return res.value[0];
}

async function createContact(_ep: ISiteAPIEndpoint, _contact: ContactBody) {
  const res = await _ep.Lists['Contacts'].All.Items.postJson(undefined, _contact);
  return res[0];
}

async function updateContact(_ep: ISiteAPIEndpoint, _contact: ContactBody) {
  const res = await _ep.Lists['Contacts'].All.Items[_contact.id!].putJson(undefined, _contact);
  return res;
}

export async function updateVisitor(_ep: ISiteAPIEndpoint, body: Partial<VisitorListItem>) {
  const res = await _ep.Lists['Visitors'].All.Items[body.id!].putJson(undefined, body);
  return res;
}

export async function createLead(ep: ISiteAPIEndpoint, body: LeadBody) {
  const res = await ep.Lists['Leads'].All.Items.postJson(undefined, body);
  return res[0];
}

export async function updateTour(ep: ISiteAPIEndpoint, body: LeadBody) {
  const res = await ep.Lists['Tours'].All.Items[body.id!].putJson(undefined, body);
  return res;
}

export async function updateLead(ep: ISiteAPIEndpoint, body: LeadBody) {
  const res = await ep.Lists['Leads'].All.Items[body.id!].putJson(undefined, body);
  return res;
}

export async function updateOrientation(ep: ISiteAPIEndpoint, body: OrientationListItem) {
  const res = await ep.Lists['Orientations'].All.Items[body.id!].putJson(undefined, body);
  return res;
}

export async function createTour(ep: ISiteAPIEndpoint, body: TourBody): Promise<TourBody> {
  const res = await ep.Lists['Tours'].All.Items.postJson(undefined, body);
  return res[0];
}

export async function createVisitor(ep: ISiteAPIEndpoint, body: Partial<VisitorListItem>): Promise<VisitorListItem> {
  const res = await ep.Lists['Visitors'].All.Items.postJson(undefined, body);
  return res[0];
}

async function createFamily(ep: ISiteAPIEndpoint, contact: Partial<ContactBody>): Promise<FamilyBody> {
  const res = await ep.Lists['Families'].All.Items.postJson(undefined, {
    family_name: contact.first_name! + ' ' + contact.last_name!,
  });
  return res[0];
}
