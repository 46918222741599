import { Spinner } from '@rapid/sdk';
import React from 'react';
import './waiting-overlay.scss';

interface IProps {
  text: String;
}

export default function WaitingOverlay({ text }: IProps) {
  return (
    <div className="waiting-overlay">
      <div className="waiting-overlay__card">
        <Spinner size={3} />
        <h2 className="waiting-overlay__title">{text}</h2>
      </div>
    </div>
  );
}
