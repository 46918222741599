import { useCurrentSiteEndpoint, useViewContextListItemArray } from '@rapid/sdk';
import { DateTime } from 'luxon';
import { Table, TableColumnsType } from 'antd';
import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PageLayout from '../../components/page-layout/page-layout';
import { VisitorListItem } from '../../types';
import { UpdateVisitorRecord } from './sign-out-requests';
import WaitingOverlay from '../../components/waiting-overlay/waiting-overlay';

export function SignOut() {
  const [visitors] = useViewContextListItemArray() as [any[], any];
  const ep = useCurrentSiteEndpoint();
  const [isSubmitting, setSubmitting] = useState(false);
  const history = useHistory();

  const columns: TableColumnsType<VisitorListItem> = useMemo(
    () => [
      {
        title: 'Details',
        dataIndex: 'primary_contact',
        ellipsis: true,
        key: 'primary_contact',
        align: 'left',
        render: (primary_contact: string) => (
          <p title={primary_contact} className="row-title ellipsis">
            {primary_contact ?? '( - )'}
          </p>
        ),
      },
      {
        title: 'Visitor Type',
        dataIndex: 'visit_reason',
        key: 'visit_reason',
        ellipsis: true,
        width: 250,
        align: 'left',
        render: (visit_reason: string) => <p>{visit_reason}</p>,
      },
      {
        title: 'Sign-In Time',
        dataIndex: 'sign_in_time',
        key: 'sign_in_time',
        ellipsis: true,
        width: 120,
        align: 'center',
        render: (sign_in_time: string) => {
          const date = DateTime.fromISO(sign_in_time, { locale: 'en-au' });
          const isValid = date.isValid;
          return <p>{isValid ? date.toLocaleString(DateTime.TIME_SIMPLE) : '-'}</p>;
        },
      },
      {
        title: 'Action',
        key: 'action',
        width: 130,
        align: 'center',
        render: (_: unknown, record: VisitorListItem) => (
          <button type="button" onClick={onSignOutClicked(record)} className="btn btn-primary">
            SIGN-OUT
          </button>
        ),
      },
    ],
    [visitors.length],
  );

  const onSignOutClicked = (visitor: VisitorListItem) => async () => {
    try {
      setSubmitting(true);

      await UpdateVisitorRecord(
        { id: visitor.id, status: 'SignedOut', sign_out_time: DateTime.now().toUTC().toISO() },
        ep,
      );

      history.push('/');
    } catch (e) {
      setSubmitting(false);
    }
  };

  return (
    <PageLayout title="Sign-Out Visitors">
      {isSubmitting && <WaitingOverlay text="Please Wait" />}

      <Table
        rowKey="id"
        className="guardian-table"
        columns={columns}
        dataSource={visitors}
        pagination={false}
        size="middle"
      />
    </PageLayout>
  );
}
