import { AnyListItem } from '@rapid/data-model';
import { useCurrentSiteEndpoint, useViewContextListItemArray } from '@rapid/sdk';
import { Table, TableColumnsType } from 'antd';
import { DateTime } from 'luxon';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PageLayout from '../../components/page-layout/page-layout';
import WaitingOverlay from '../../components/waiting-overlay/waiting-overlay';
import { useContextualData } from '../../contexts/contextual-data';
import { useCurrentCenterUser } from '../../contexts/current-center-user';
import { RecurringVisitorBody } from '../../types';
import { createVisitor } from '../form-viewer/submission/general';
import './recurring-visitors.scss';

interface RecurringVisitorsProps {}

function RecurringVisitors(props: RecurringVisitorsProps) {
  const [, setContextualData] = useContextualData();
  const [recurringVisitors, setRecurringVisitors] = useState([]);
  const [, context] = useViewContextListItemArray();
  const ep = useCurrentSiteEndpoint();
  const [isSubmitting, setSubmitting] = useState(false);
  const history = useHistory();
  const centreUser = useCurrentCenterUser();
  const [skip, setSkip] = useState<any>();

  const columns: TableColumnsType<RecurringVisitorBody> = useMemo(
    () => [
      {
        title: 'Name',
        dataIndex: 'primary_contact',
        ellipsis: true,
        key: 'primary_contact',
        align: 'left',
        render: (primary_contact: string) => (
          <div title={primary_contact} className="ellipsis">
            <p>{primary_contact ?? '( - )'}</p>
          </div>
        ),
      },
      {
        title: 'Last Verification Date',
        dataIndex: 'last_verification_date',
        key: 'last_verification_date',
        ellipsis: true,
        width: 120,
        align: 'center',
        render: (last_verification_date: string) => {
          const date = DateTime.fromISO(last_verification_date, { locale: 'en-au' });
          const isValid = date.isValid;
          return <p>{isValid ? date.toRelativeCalendar() : '-'}</p>;
        },
      },
      {
        title: 'Sign-In',
        key: 'action',
        width: 130,
        align: 'center',
        render: (_: unknown, record: RecurringVisitorBody) => (
          <button type="button" onClick={onSignInClicked(record)} className="btn btn-primary">
            SIGN-IN
          </button>
        ),
      },
    ],
    [recurringVisitors.length],
  );

  const onSignInClicked = (recurring: RecurringVisitorBody) => async () => {
    try {
      setSubmitting(true);

      const contact = await ep.Lists.Contacts.All.Items[recurring.primary_contact_id].getJson();

      if (typeof contact === 'string') {
        setSubmitting(false);
        return;
      }

      const now = DateTime.now();
      const past = DateTime.fromISO(recurring.last_verification_date);
      const { months } = now.diff(past, 'months');

      if (months >= 3) {
        setContextualData({
          back_href: '/',
          data: recurring as AnyListItem,
          form_title: 'Update Recurring Visitor',
          [Symbol.for('___save_details___')]: true,
          adaptive_prefill: {
            Contacts: [
              {
                id: contact.id,
                first_name: contact.first_name,
                last_name: contact.last_name,
                mobile: contact.mobile,
                email: contact.email,
              },
            ],
          },
        });

        return history.push('/form/recurring-visitor');
      }

      await createVisitor(ep, {
        LinkedItemsToAdd: [`contacts/${contact.id}`],
        primary_contact_id: contact.primary_contact_id ?? contact.id,
        visit_reason: recurring.visitor_type as any,
        family_id: contact.family_id,
        status: 'SignedIn',
        centre_id: centreUser.centre_id!,
        sign_in_time: DateTime.now().toUTC().toISO(),
      });

      return history.push('/sign-out');
    } catch (e) {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    const response = ep.Lists[context.list.ListName][context.dataSource.Title].Items.getJson({
      $skip: skip,
      $filter: context.filter,
    });

    response.then((items) => {
      const url = new URL(`https:/${items['@odata.nextLink']}`);
      const search = new URLSearchParams(url.search);
      if (search.get('$skip')) {
        setSkip(search.get('$skip'));
      }

      if (items.value?.length) {
        setRecurringVisitors((d) => d.concat(items.value));
      }
    });
  }, [skip]);

  return (
    <PageLayout title="Recurring Visitor Sign-In">
      {isSubmitting && <WaitingOverlay text="Please Wait" />}

      <Table
        rowKey="id"
        className="guardian-table"
        columns={columns as any}
        dataSource={recurringVisitors}
        pagination={false}
        size="middle"
      />
    </PageLayout>
  );
}

export default RecurringVisitors;
