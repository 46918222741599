import { ManagedSingleSiteApplication } from '@rapid/sdk';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import PageFooter from './components/page-footer/page-footer';
import PageHeader, { UnAuthHeader } from './components/page-header/page-header';
import ContextualDataProvider from './contexts/contextual-data';
import CurrentCenterUserProvider from './contexts/current-center-user';
import AllVisitors from './routes/all-visitors';
import {
  AllOtherVisitorsAdaptiveForm,
  BookedTourAdaptiveForm,
  OrientationAdaptiveForm,
  RecurringVisitorsAdaptiveForm,
  WalkInTourAdaptiveForm,
} from './routes/form-viewer/routes';
import Home from './routes/home/home';
import NoCentreUser from './routes/no-centre-user/no-centre-user';
import Orientations from './routes/orientations';
import RecurringVisitors from './routes/recurring-visitors';
import SignOut from './routes/sign-out';
import TourBooking from './routes/tour-booking';

export default function App() {
  return (
    <>
      <UnAuthHeader />
      <ManagedSingleSiteApplication
        tenant="guardianchildcare"
        site="internal"
        env={process.env.platformEnvironment!}
        clientId="d29458ce-b767-4e2d-9f38-ad23901b389a"
        namespace="guardian::visitor_portal"
        displayName="Visitor Portal"
        className="guardian-visitor-portal"
      >
        <section className="app-content">
          <PageHeader />

          <CurrentCenterUserProvider>
            <ContextualDataProvider>
              <Switch>
                <Route path="/home">
                  <Home />
                </Route>

                <Route path="/orientations">
                  <Orientations />
                </Route>

                <Route path="/all-visitors">
                  <AllVisitors />
                </Route>

                <Route path="/tour-booking">
                  <TourBooking />
                </Route>

                <Route path="/recurring-visitors">
                  <RecurringVisitors />
                </Route>

                {/* Adaptive Forms */}
                <Route path="/form/walk-in-tour" component={WalkInTourAdaptiveForm} />
                <Route path="/form/orientation" component={OrientationAdaptiveForm} />
                <Route path="/form/booked-tour" component={BookedTourAdaptiveForm} />
                <Route path="/form/recurring-visitor" component={RecurringVisitorsAdaptiveForm} />
                <Route path="/form/all-other-visitors/:visitors_form_id" component={AllOtherVisitorsAdaptiveForm} />

                <Route path="/sign-out">
                  <SignOut />
                </Route>

                <Route path="/no-centre-user">
                  <NoCentreUser />
                </Route>

                <Route exact path="">
                  <Redirect to="/home" />
                </Route>
              </Switch>
            </ContextualDataProvider>
          </CurrentCenterUserProvider>

          <PageFooter />
        </section>
      </ManagedSingleSiteApplication>
    </>
  );
}
