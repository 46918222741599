import { ManagedViewContext } from '@rapid/sdk';
import { DateTime } from 'luxon';
import React from 'react';
import { useCurrentCenterUser } from '../../contexts/current-center-user';
import { SignOut } from './sign-out';

export default function SignOutHOC() {
  const user = useCurrentCenterUser();
  const endOfDay = DateTime.now().endOf('day');
  const endOfDayStr = endOfDay.toUTC().toISO();

  return (
    <ManagedViewContext
      list="Visitors"
      dataSource="Visitors%20Portal"
      columns={['id', 'visit_reason', 'sign_in_time', 'primary_contact']}
      filter={`centre_id eq '${user.centre_id}' and sign_in_time le '${endOfDayStr}' and status eq 'SignedIn'`}
    >
      <SignOut />
    </ManagedViewContext>
  );
}
