import { findParentConnection, IElement } from '@rapid/adaptive-framework';
import { useState } from 'react';

interface IFormError {
  error: string;
  elementID: string;
}

interface DefaultState {
  processing: boolean;
  displayText: null | string;
  errors: Array<IFormError>;
  hasErrors: boolean;
}

const defaultState: DefaultState = {
  processing: false,
  displayText: null,
  errors: [],
  hasErrors: false,
};

export function useFormSubmission() {
  const [state, setState] = useState<DefaultState>(defaultState);
  return [state, setState];
}

type ConnectionID = string;

export interface IFormData {
  connections: ConnectionID[];
  value: Record<ConnectionID, any>;
  globalChoices: Record<string | symbol, any>;
  groupData?: Record<string, string[]>;
}

export interface IProcessedFormData {
  Contacts: Record<string, any>;
  Visitors: Record<string, any>;
  [key: string]: any;
}

function getAllFields(el: IElement, required: any, data: IFormData, group?: string) {
  if (el.title === 'save_details') return;

  if (el.$type.includes('Input')) {
    const [listName, column] = el.id.split(':');
    const title = el?.title ?? el?.attributes?.label ?? column;

    if (!el?.attributes?.hidden && !required[listName].includes([title, column, group])) {
      required[listName].push([title, column, group]);
    }
  }

  if (!el.$type.includes('Input') && el.$children?.length) {
    for (const child of el.$children) {
      getAllFields(child, required, data, (child as any)?.datum?.validationGroup?.value ?? group);
    }
  }

  return;
}

function process(data: IFormData, schema: IElement, errors: any[], groupErrors: any): void | IProcessedFormData[] {
  const requiredFields: any = { Visitors: [], Contacts: [] };
  getAllFields(schema, requiredFields, data);

  //Only one connection will have data
  const connectionID = data.connections.find((connection) => {
    const focusedData = data.value[connection];
    return !focusedData || !focusedData?.length ? false : connection;
  });

  if (!connectionID) return;

  return data.value[connectionID].reduce((result: IProcessedFormData[], field: any) => {
    const fieldIds = Object.keys(field);
    const returnValue: IProcessedFormData = {
      Visitors: {},
      Contacts: {},
    };

    //Group flat field object by ListName
    for (const fieldId of fieldIds) {
      const [listName, column] = fieldId.split(':');
      if (!column) continue;
      if (!returnValue[listName]) returnValue[listName] = {};
      returnValue[listName][column] = field[fieldId]?.data;
    }

    returnValue['Visitors'] = { ...returnValue['Visitors'], ...data.globalChoices };

    for (const [key, value] of Object.entries(returnValue)) {
      for (const requiredField of requiredFields[key]) {
        if (errors.includes(requiredField[0])) continue;

        if (!value.hasOwnProperty(requiredField[1]) && !requiredField[2]) {
          errors.push(requiredField[0]);
          continue;
        }

        if (requiredField[2]) {
          if (!groupErrors[requiredField[2]]) groupErrors[requiredField[2]] = [];
          groupErrors[requiredField[2]].push([!!value[requiredField[1]], requiredField[0]]);
          continue;
        }

        if (!value[requiredField[1]] || value[requiredField[1]] === 'No') errors.push(requiredField[0]);
      }
    }

    result.push(returnValue);
    return result;
  }, []);
}

export function adaptiveFormChangeHandler(
  element: IElement,
  data: unknown,
  path: string,
  formData: IFormData,
  formSchema: IElement,
) {
  const pConnection = findParentConnection(element, formSchema);

  if (pConnection && !formData.connections.includes(pConnection.id)) {
    formData.connections.push(pConnection.id);
  }

  if (element.title === 'save_details' && element.$type === 'Input.Boolean') {
    formData.globalChoices[Symbol.for('___save_details___')] = data;
  }

  const dataPath = /\["(.*?)"\]$/.exec(path);
  const [, column] = typeof dataPath?.[1] === 'string' ? dataPath[1].split(':') : element.id.split(':');
  if (!path) formData.globalChoices[column] = data;
}

export function validateFormData(formData: IFormData, schema: IElement) {
  const errors: any[] = [];
  const groupErrors: Record<string, Array<[boolean, string]>> = {};
  const data = process(formData, schema, errors, groupErrors) ?? null;
  return { errors, data, groupErrors };
}
