import { ISiteAPIEndpoint } from '@rapid/sdk/lib/api/rapid-platform.api';
import { TourListItem, VisitorListItem } from '../../types';

export async function GetPrimaryContactID(tour: TourListItem, ep: ISiteAPIEndpoint): Promise<string | VisitorListItem> {
  try {
    const { Leads } = await ep.Lists.Tours.All.Items[tour.id].Links.getJson({ lists: 'Leads' });
    if (!Leads?.count) return 'No Lead linked to Tour';

    const leadId = Leads.items[0].id;
    const lead = await ep.Lists.Leads.All.Items[leadId].getJson();
    if (!lead.primary_contact_id) return 'No Primary Contact on Lead';

    return lead;
  } catch (e: unknown) {
    return 'Unable to sign in user';
  }
}
