import { WindowFetch } from '@rapid/sdk';
import { ISiteAPIEndpoint } from '@rapid/sdk/lib/api/rapid-platform.api';
import { IApplicationContext } from '@rapid/sdk/lib/rapid-application';
import { DateTime } from 'luxon';
import { IData } from '../../../contexts/contextual-data';
import { ICenterUser } from '../../../contexts/current-center-user';
import { ContactBody } from '../../../types';
import { IProcessedFormData } from '../utils';
import {
  createVisitor,
  getContactOrCreate,
  getRecurringVisitorOrCreate,
  processFiles,
} from './general';

export async function RecurringVisitors(
  data: IProcessedFormData[],
  ep: ISiteAPIEndpoint,
  app: IApplicationContext,
  graph: WindowFetch,
  centreUser: ICenterUser,
  contextualData: Partial<IData>,
) {
  for (let i = 0; i < data.length; i++) {
    const rawContact = data[i]['Contacts'] as ContactBody;
    const rawVisitor = data[i]['Visitors'] as any;
    const file = processFiles(rawVisitor)[0];

    const { contact } = await getContactOrCreate(ep, rawContact, false);
    let recurringVisitor = null;

    const save_details = (contextualData as any)?.[Symbol.for('___save_details___')];

    if (rawVisitor[Symbol.for('___save_details___')] || !!save_details) {
      recurringVisitor = await getRecurringVisitorOrCreate(ep, contact, centreUser, contextualData);
    }

    const payload: any = {
      primary_contact_id: contact.id as number,
      visit_reason: contextualData.data!.visitor_type as any,
      status: 'SignedIn',
      centre_id: centreUser.centre_id!,
      sign_in_time: DateTime.now().toUTC().toISO(),
      ...rawVisitor,
    };

    if (recurringVisitor) payload.LinkedItemsToAdd = [`recurring_visitor/${recurringVisitor.id}`];

    const visitor = await createVisitor(ep, payload);
  }
}
