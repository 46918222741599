import { Spinner } from '@rapid/sdk';
import React from 'react';
import './spinner.scss';

export default function () {
  return (
    <div className="spinner">
      <Spinner />
    </div>
  );
}
