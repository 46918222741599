import { useCurrentSiteEndpoint, useViewContextListItemArray } from '@rapid/sdk';
import { DateTime } from 'luxon';
import { Table, TableColumnsType } from 'antd';
import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PageLayout from '../../components/page-layout/page-layout';
import { OrientationListItem } from '../../types';
import { GetPrimaryContactID } from './orientations-requests';
import WaitingOverlay from '../../components/waiting-overlay/waiting-overlay';
import { useContextualData } from '../../contexts/contextual-data';

export function Orientations() {
  const [contextualData, setContextualData] = useContextualData();
  const [orientations] = useViewContextListItemArray() as [any[], any];
  const [isSubmitting, setSubmitting] = useState(false);
  const ep = useCurrentSiteEndpoint();
  const history = useHistory();

  const columns: TableColumnsType<OrientationListItem> = useMemo(
    () => [
      {
        title: 'Details (Family Name)',
        dataIndex: 'family',
        ellipsis: true,
        key: 'family',
        align: 'left',
        render: (family: string) => (
          <p title={family} className="row-title ellipsis">
            {family ?? '( No Family )'}
          </p>
        ),
      },
      {
        title: 'Booking Time',
        dataIndex: 'orientation_booking_date_and_time',
        key: 'orientation_booking_date_and_time',
        ellipsis: true,
        width: 120,
        align: 'center',
        render: (orientation_booking_date_and_time: string) => {
          const date = DateTime.fromISO(orientation_booking_date_and_time, { locale: 'en-au' });
          const isValid = date.isValid;
          return <p>{isValid ? date.toLocaleString(DateTime.TIME_SIMPLE) : '-'}</p>;
        },
      },
      {
        title: 'Sign-In',
        key: 'action',
        width: 130,
        align: 'center',
        render: (_: unknown, record: OrientationListItem) => (
          <button type="button" onClick={onSignInClicked(record)} className="btn btn-primary">
            SIGN-IN
          </button>
        ),
      },
    ],
    [orientations.length],
  );

  const onSignInClicked = (orientation: OrientationListItem) => async () => {
    try {
      setSubmitting(true);

      const lead = await GetPrimaryContactID(orientation, ep);

      if (typeof lead === 'string') {
        setSubmitting(false);
        return;
      }

      setContextualData({
        back_href: '/',
        data: orientation,
        form_title: 'Orientation',
        adaptive_prefill: {
          Contacts: [
            {
              id: lead.primary_contact_id,
              first_name: lead.contact_first,
              last_name: lead.contact_last,
              mobile: lead.contact_mobile,
              email: lead.contact_email,
            },
          ],
        },
      });

      history.push('/form/orientation');
    } catch (e) {
      setSubmitting(false);
    }
  };

  return (
    <PageLayout title="Today's Orientations">
      {isSubmitting && <WaitingOverlay text="Please Wait" />}

      <Table
        rowKey="id"
        className="guardian-table"
        columns={columns}
        dataSource={orientations}
        pagination={false}
        size="middle"
      />
    </PageLayout>
  );
}
