import React from 'react';
import { ManagedViewContext } from '@rapid/sdk';
import { DateTime } from 'luxon';
import { useCurrentCenterUser } from '../../contexts/current-center-user';
import { Orientations } from './orientations';

export default function TourBookingHOC() {
  const user = useCurrentCenterUser();
  const endOfDay = DateTime.now().endOf('day');
  const startOfDay = DateTime.now().startOf('day');

  const endOfDayStr = endOfDay.toUTC().toISO();
  const startOfDayStr = startOfDay.toUTC().toISO();
  const filter = `centre_id eq '${user.centre_id}' and orientation_status eq 'Orientation Booked' and orientation_booking_date_and_time le '${endOfDayStr}' and orientation_booking_date_and_time ge '${startOfDayStr}'`;

  return (
    <ManagedViewContext list="Orientations" dataSource="Visitors%20Portal%20Orientations" filter={filter}>
      <Orientations />
    </ManagedViewContext>
  );
}
