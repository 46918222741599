import React from 'react';
import FormCard from '../../components/form-card/form-card';
import PageLayout from '../../components/page-layout/page-layout';

const details = [
  {
    title: 'Booked Centre Tour',
    color: '#f7acb4',
    to: '/tour-booking',
  },
  {
    title: 'Walk-in Tour',
    color: '#ffcb1f',
    to: '/form/walk-in-tour',
  },
  {
    title: 'All Other Visitors',
    color: '#15254f',
    to: '/all-visitors',
  },
  {
    title: 'Booked Orientation',
    color: '#007e72',
    to: '/orientations',
  },
  {
    title: 'Recurring Visitors',
    color: 'hsl(310, 43%, 31%)',
    to: '/recurring-visitors',
  },
];

export default function Home() {
  return (
    <PageLayout hideBack title="Home">
      <div className="form-card-container">
        {details.map(({ to, title, color }) => (
          <FormCard key={color} title={title} href={to} baseColor={color} />
        ))}
      </div>
    </PageLayout>
  );
}
