import { ISiteAPIEndpoint } from '@rapid/sdk/lib/api/rapid-platform.api';
import { VisitorStatus } from '../../types';

export async function UpdateVisitorRecord(
  body: { status: VisitorStatus; sign_out_time: string; id: number },
  ep: ISiteAPIEndpoint,
) {
  try {
    return await ep.Lists.Visitors.All.Items[body.id].putJson(undefined, body);
  } catch (e) {}
}
