import { useViewContext } from '@rapid/sdk';
import React, { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import Spinner from '../spinner/spinner';
import './page-layout.scss';

export interface IPageLayoutProps {
  title: string;
  hideBack?: boolean;
  backHref?: string;
}

export default function PageLayout({ title, hideBack, backHref, children }: PropsWithChildren<IPageLayoutProps>) {
  const viewContext = useViewContext();
  const view = viewContext?.[0];

  const body = typeof view === 'undefined' ? children : view.loading ? <Spinner /> : children;

  return (
    <main className="page-layout">
      <div className="page-layout__header">
        {hideBack !== true && (
          <Link to={backHref ?? '/home'} type="button" className="btn btn-link">
            <i className="fa-solid fa-arrow-left" />
            <p>Back</p>
          </Link>
        )}
        <h1 className="page-layout__title">{title}</h1>
      </div>

      <section className="page-layout__body">{body}</section>
    </main>
  );
}
