import { WindowFetch } from '@rapid/sdk';
import { ISiteAPIEndpoint } from '@rapid/sdk/lib/api/rapid-platform.api';
import { IApplicationContext } from '@rapid/sdk/lib/rapid-application';
import { DateTime } from 'luxon';
import { IData } from '../../../contexts/contextual-data';
import { ICenterUser } from '../../../contexts/current-center-user';
import { ContactBody, LeadBody, TourBody } from '../../../types';
import { IProcessedFormData } from '../utils';
import {
  createLead,
  createTour,
  createVisitor,
  getContactOrCreate,
  processFiles,
} from './general';

export async function WalkInTours(
  data: IProcessedFormData[],
  ep: ISiteAPIEndpoint,
  app: IApplicationContext,
  graph: WindowFetch,
  centreUser: ICenterUser,
  contextualData: Partial<IData>,
) {
  let lead: LeadBody | undefined, tour: TourBody | undefined;

  for (let i = 0; i < data.length; i++) {
    const rawContact = data[i]['Contacts'] as ContactBody;
    const rawVisitor = data[i]['Visitors'];
    const file = processFiles(rawVisitor)[0];

    const { contact, family_id } = await getContactOrCreate(ep, rawContact);

    if (i === 0 && !contact.lead_link) {
      lead = await createLead(ep, {
        ScopedLinkedItemsToAdd: [`Centers/${centreUser.centre_id}`],
        primary_contact_id: contact.id as number,
        family_id: family_id,
        enquiry_type: 'Walk-in Tour',
        enquiry_stage: 'Active',
        enquiry_source: app.currentPrincipalUpn!,
      });
    }

    if (i === 0) {
      tour = await createTour(ep, {
        LinkedItemsToAdd: [`Leads/${!!lead?.id ? lead?.id : contact.lead_link}`],
        tour_booking_date_and_time: DateTime.now().toUTC().toISO(),
        tour_booking_source: 'visitor_log',
        tour_status: 'Attended',
        centre_id: centreUser.centre_id as number,
        family_id: family_id,
      });
    }

    const visitor = await createVisitor(ep, {
      LinkedItemsToAdd: !!tour ? [`Tours/${tour.id}`] : [],
      primary_contact_id: contact.id as number,
      visit_reason: 'Walk-in Tour',
      family_id: family_id,
      status: 'SignedIn',
      centre_id: centreUser.centre_id!,
      sign_in_time: DateTime.now().toUTC().toISO(),
      ...rawVisitor,
    });
  }
}
