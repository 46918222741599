import chroma from 'chroma-js';
import React from 'react';
import { Link } from 'react-router-dom';
import './form-card.scss';

interface IProps {
  title: string;
  baseColor: string;
  href: string;
}

export default function FormCard({ baseColor, title, href }: IProps) {
  const chromeColor = chroma(baseColor);
  const backgroundColor = chromeColor.css();

  return (
    <Link to={href} className="form-card" title={title} style={{ backgroundColor }}>
      <h3 className="form-card__title">{title}</h3>
    </Link>
  );
}
