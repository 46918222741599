import { IElement } from '@rapid/adaptive-framework';
import { AnyListItem } from '@rapid/data-model';
import React, { createContext, Dispatch, PropsWithChildren, SetStateAction, useContext, useState } from 'react';

export interface IData {
  page: 'orientation' | 'booked-tours' | 'walk-in-tour' | 'all-other-visitors';
  adaptive_prefill: object;
  form_id: number;
  document?: { body?: IElement };
  form_title: string;
  back_href: string;
  data?: AnyListItem;
}

export const ContextualDataContext = createContext([] as any);

export function useContextualData(): [Partial<IData>, Dispatch<SetStateAction<Partial<IData>>>] {
  return useContext(ContextualDataContext);
}

export default function ContextualDataProvider({ children }: PropsWithChildren<unknown>) {
  const methods = useState<Partial<IData>>({});
  return <ContextualDataContext.Provider value={methods}>{children}</ContextualDataContext.Provider>;
}
