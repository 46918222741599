import React from 'react';
import PageLayout from '../../components/page-layout/page-layout';
import './no-centre-user.scss';

export default function NoCentreUser() {
  return (
    <PageLayout hideBack title="">
      <div className="no-centre-user">
        <h1>You're not a part of a centre</h1>
        <h4>Please contact your manager if this is a mistake</h4>
      </div>
    </PageLayout>
  );
}
