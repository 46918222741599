import { ManagedListItemContext, useAsyncEffect, useCurrentSiteEndpoint } from '@rapid/sdk';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Spinner from '../../components/spinner/spinner';
import { useContextualData } from '../../contexts/contextual-data';
import { VisitorFormListItem } from '../../types';
import FormViewer from './form-viewer';
import settings from '../../settings.json';

const forms = settings[process.env.platformEnvironment as keyof typeof settings];

export function WalkInTourAdaptiveForm() {
  return (
    <ManagedListItemContext list="Adaptive Documents" id={forms.adaptiveFormIDs.WalkInTour}>
      <FormViewer />
    </ManagedListItemContext>
  );
}

export function OrientationAdaptiveForm() {
  return (
    <ManagedListItemContext list="Adaptive Documents" id={forms.adaptiveFormIDs.Orientation}>
      <FormViewer />
    </ManagedListItemContext>
  );
}

export function BookedTourAdaptiveForm() {
  return (
    <ManagedListItemContext list="Adaptive Documents" id={forms.adaptiveFormIDs.BookedTour}>
      <FormViewer />
    </ManagedListItemContext>
  );
}

export function RecurringVisitorsAdaptiveForm() {
  return (
    <ManagedListItemContext list="Adaptive Documents" id={forms.adaptiveFormIDs.RecurringVisitors}>
      <FormViewer />
    </ManagedListItemContext>
  );
}

export function AllOtherVisitorsAdaptiveForm() {
  const [contextualData, setContextualData] = useContextualData();
  const ep = useCurrentSiteEndpoint();
  const [visitor, setVisitor] = useState<VisitorFormListItem | null>(null);
  const params = useParams<{ visitors_form_id: string }>();
  const form_id = +params?.visitors_form_id;

  useAsyncEffect(async () => {
    const _visitor: VisitorFormListItem = await ep.Lists['Visitor Types'].All.Items[form_id].getJson();
    if (!_visitor) return setVisitor(null);
    else setVisitor(_visitor);

    setContextualData({
      back_href: '/all-visitors',
      data: {
        ..._visitor,
        visitor_type_id: _visitor.id,
      },
      form_id: _visitor?.document_id,
      form_title: _visitor.title,
    });
  }, [form_id]);

  if (!visitor?.document_id) {
    return (
      <main className="tour-booking-route">
        <Spinner />
      </main>
    );
  }

  return (
    <ManagedListItemContext list="Adaptive Documents" id={visitor.document_id}>
      <FormViewer />
    </ManagedListItemContext>
  );
}
