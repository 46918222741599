import React from 'react';
import './page-footer.scss';

export default function PageFooter() {
  return (
    <footer className="page-footer">
      <p className="guardian-green">&copy; Guardian 2022</p>
      <a className="btn btn-link" href="https://www.guardian.edu.au/privacy-policy/" target="_blank">
        Privacy Policy
      </a>
    </footer>
  );
}
