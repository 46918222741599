import { useMsal, useRapidApplication } from '@rapid/sdk';
import React from 'react';
import { createPortal } from 'react-dom';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Logo from '../../assets/logo.svg';
import './page-header.scss';

export default function PageHeader() {
  const [app] = useRapidApplication();
  const msal = useMsal();
  const location = useLocation();
  const history = useHistory();
  const isSignOut = location.pathname.includes('/sign-out');
  const headerRef = document.getElementById('guardian-header-id')!;

  const logout = () => {
    msal.logoutPopup().then(() => {
      history.push('/home');
      window.location.reload();
    });
  };

  const onNavHome = () => {
    history.push('/home');
  };

  return createPortal(
    <>
      <div id="navigate-home" onClick={onNavHome} style={{ display: 'none' }} aria-hidden />

      <div className="upn-container">
        <p>{app.currentPrincipalUpn}</p>
        <button type="button" onClick={logout} className="btn btn-link">
          Logout
        </button>
      </div>

      <div className="btn-group">
        <Link to="/home" className={`btn ${!isSignOut ? 'btn--active' : ''} btn-secondary`} role="link">
          Sign-In
        </Link>
        <Link to="/sign-out" className={`btn ${isSignOut ? 'btn--active' : ''} btn-secondary`} role="link">
          Sign-Out
        </Link>
      </div>
    </>,
    headerRef,
  );
}

export function UnAuthHeader() {
  const onClick = () => {
    const home = document.getElementById('navigate-home');
    if (home) home.click();
  };

  return (
    <header className="page-header" id="guardian-header-id">
      <a onClick={onClick} className="logo-link">
        <Logo className="page-header__logo" />
      </a>
    </header>
  );
}
