import {
  actionRegistry,
  IElement,
  UncontrolledAdaptiveRenderer,
  useAdaptiveData,
  useRapidUpdater,
} from '@rapid/adaptive-framework';
import { useComposedClassName } from '@rapid/sdk';
import React, { useEffect } from 'react';
import './adaptive-form.scss';

interface IProps {
  rendererId?: string;
  schema?: IElement;
  preFill?: any;
  className?: string;
  onAdaptiveFormChange?: (element: IElement, data: string, path: string, key: string) => void;
  onData?: (data: any) => void;
}

const defaultSchema: IElement = {
  $type: 'Layout.Section',
  id: 'root',
};

function AdaptiveForm({ onData, rendererId, schema, preFill, onAdaptiveFormChange, className }: IProps) {
  const [update, forceUpdate] = useRapidUpdater();

  const [adaptiveData, updateData, onAdaptiveDataChange, loading] = useAdaptiveData(
    preFill,
    schema ?? defaultSchema,
    forceUpdate,
    'normal',
    [],
  );

  const formClassName = useComposedClassName(
    function* () {
      yield 'adaptive-form';
      if (className) yield className;
    },
    [className],
  );

  const onDataChangeInner = (element: IElement, data: any, path?: any, key?: any) => {
    onAdaptiveDataChange(element, data, path, key);
    onAdaptiveFormChange?.(element, data, path, key);
  };

  useEffect(
    function () {
      onData?.(adaptiveData);
    },
    [adaptiveData],
  );

  async function actionFunction(e: any) {
    if (!e.args?.action) {
      return;
    }

    (e.args?.action as (args: any) => Promise<any>)({
      ...e.args,
      data: adaptiveData,
      connections: e.args.connectionsTable,
      updateData,
    });
  }

  useEffect(
    function addEventListenerEffect() {
      actionRegistry.addEventListener(`action:${rendererId}`, actionFunction);

      return function removeEventListenerEffect() {
        actionRegistry.removeEventListener(`action:${rendererId}`, actionFunction);
      };
    },
    [updateData, adaptiveData],
  );

  return (
    <div className={formClassName}>
      {schema && !loading && (
        <UncontrolledAdaptiveRenderer
          rendererId={rendererId}
          update={update}
          forceUpdate={forceUpdate}
          onDataChange={onDataChangeInner}
          data={adaptiveData}
          schema={schema}
        />
      )}
    </div>
  );
}

export default AdaptiveForm;
