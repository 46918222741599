import React from 'react';
import { ManagedViewContext } from '@rapid/sdk';
import { DateTime } from 'luxon';
import { useCurrentCenterUser } from '../../contexts/current-center-user';
import { TourBooking } from './tour-booking';

export default function TourBookingHOC() {
  const user = useCurrentCenterUser();
  const endOfDay = DateTime.now().endOf('day');
  const startOfDay = DateTime.now().startOf('day');

  const endOfDayStr = endOfDay.toUTC().toISO();
  const startOfDayStr = startOfDay.toUTC().toISO();
  const filter = `centre_id eq '${user.centre_id}' and tour_status eq 'Tour Booked' and tour_booking_date_and_time le '${endOfDayStr}' and tour_booking_date_and_time ge '${startOfDayStr}'`;

  return (
    <ManagedViewContext
      columns={['id', 'parent_name', 'lead_email', 'tour_booking_date_and_time', 'centre_id', 'family_id']}
      list="Tours"
      dataSource="Portal%20Visitor%20Tours"
      filter={filter}
    >
      <TourBooking />
    </ManagedViewContext>
  );
}
