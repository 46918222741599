import { ManagedViewContext } from '@rapid/sdk';
import React from 'react';
import AllVisitors from './all-visitors';

export default function AllVisitorsHOC() {
  return (
    <ManagedViewContext list="Visitor Types">
      <AllVisitors />
    </ManagedViewContext>
  );
}
